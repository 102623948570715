// Plus Icon:

import React from 'react'

// ___________________________________________________________________

const Plus = () => (
  <svg width="18" viewBox="0 0 32 32">
    <path d="M14 0v14h-14v3h14v14h3v-14h14v-3h-14v-14h-3z" />
  </svg>
)

export default Plus
