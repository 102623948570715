// Arrow Icon:
// Next Arrow (>) icon.

import React from 'react'

// ___________________________________________________________________

const NextArrow = () => (
  <svg
    width="22"
    height="24"
    viewBox="0 0 22 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.6324 14.6021L11.648 23.5865C11.0967 24.1378 10.2028 24.1378 9.65145 23.5865L0.667034 14.6021C0.115705 14.0508 0.115705 13.1569 0.667034 12.6056C1.21836 12.0542 2.11224 12.0542 2.66357 12.6056L9.23795 19.1799L9.23795 5.16142e-07L12.0615 3.92721e-07L12.0615 19.1799L18.6359 12.6056C19.1872 12.0542 20.0811 12.0542 20.6324 12.6056C21.1837 13.1569 21.1837 14.0508 20.6324 14.6021Z"
      fill="var(--color-text)"
    />
  </svg>
)

export default NextArrow
