// Chevron Icon:

import React from 'react'

// ___________________________________________________________________

const Chevron = () => (
  <svg
    width="11"
    viewBox="0 0 11 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.285 8.69002L3.13313 15.3038C2.7882 15.6228 2.22899 15.6228 1.8841 15.3038L1.04995 14.5324C0.705608 14.214 0.704946 13.6979 1.04848 13.3787L4.35104 10.3102C5.62837 9.12345 5.62836 7.10148 4.35103 5.91469L1.04848 2.84623C0.704946 2.52704 0.705608 2.01095 1.04995 1.69252L1.8841 0.921119C2.22902 0.60214 2.78824 0.60214 3.13313 0.921119L10.285 7.53495C10.6299 7.85389 10.6299 8.37104 10.285 8.69002Z"
    />
  </svg>
)

export default Chevron
