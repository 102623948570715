// Pin Icon:
// For location indication

// Core
import React from 'react'

// ___________________________________________________________________

const Pin = () => (
  <svg width="14" viewBox="0 0 16 20">
    <path
      d="M8,0 C12.418278,0 16,2.6622633 16,8.42209145 C16,14.1819196 8,20 8,20 C8,20 0,14.1819196 0,8.42209145 C0,2.6622633 3.581722,0 8,0 Z M8,4 C5.790861,4 4,5.790861 4,8 C4,10.209139 5.790861,12 8,12 C10.209139,12 12,10.209139 12,8 C12,5.790861 10.209139,4 8,4 Z"
      id="Combined-Shape"
    />
  </svg>
)

export default Pin
