// Checkmark Icon:

import React from 'react'

// ___________________________________________________________________

const Checkmark = () => (
  <svg
    width="16"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0766 0.959473C14.0766 0.959422 14.0767 0.959395 15.0238 1.87167C15.971 2.78396 15.971 2.78398 15.9709 2.78402L15.6987 3.06686C15.5231 3.24929 15.2688 3.51359 14.954 3.84093C14.3244 4.49563 13.4531 5.40246 12.4858 6.41095C10.5502 8.42908 8.23442 10.8505 6.70326 12.4741L5.80103 13.4308L0.970947 8.8761L2.77539 6.96257L5.69358 9.71441C7.1557 8.17325 9.00174 6.24391 10.5876 4.59039C11.5559 3.58087 12.428 2.6732 13.0582 2.01791C13.3732 1.69025 13.6278 1.42567 13.8036 1.24299L14.0766 0.959473Z"
      fill="white"
    />
  </svg>
)

export default Checkmark
