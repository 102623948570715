// Carat Icon:

import React from 'react'

// ___________________________________________________________________

const Carat = () => (
  <svg
    width="32"
    viewBox="0 0 26 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.20711 0C0.761655 0 0.538572 0.538571 0.853554 0.853553L12.6464 12.6464C12.8417 12.8417 13.1583 12.8417 13.3536 12.6464L25.1464 0.853554C25.4614 0.538572 25.2383 0 24.7929 0L1.20711 0Z"
    />
  </svg>
)

export default Carat
